import { MasterCategoryList } from "./../models/master-cat-list";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class CategoryService {
  private masterCategoryURL =
    "https://data.autosupplyhouse.com/web/services/categories";

  constructor(private http: HttpClient) {}

  getMasterCategories() {
    return this.http
      .get(this.masterCategoryURL)
      .map((response) => response["CATEGORIES"]);
  }

  getSubCategories(category: string) {
    let URL = this.masterCategoryURL + "/category/" + category;
    return this.http.get(URL).map((response) => response["SUBCATS"]);
  }
}
