import { SearchList } from "./../models/search-list";
import { Component, OnInit } from "@angular/core";
import { ProductsService } from "../services/products.service";
import { BsNavbarComponent } from "../bs-navbar/bs-navbar.component";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { GlobalApp } from "../global";

@Component({
  selector: "app-product-search",
  providers: [BsNavbarComponent],
  templateUrl: "./product-search.component.html",
  styleUrls: ["./product-search.component.css"],
})
export class ProductSearchComponent implements OnInit {
  searchList: SearchList[];
  public button;
  public added = false;
  public searchData: string;
  public _opened: boolean = true;

  constructor(
    private navbar: BsNavbarComponent,
    private service: ProductsService,
    private route: ActivatedRoute,
    private _route: Router,
    public app: GlobalApp
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.searchData = params.get("searchData");
      this.getSearch(this.searchData);
    });
  }

  getSearch(searchData) {
    this.service.getSearchProducts(searchData.toUpperCase()).subscribe((s) => {
      this.searchList = s;
    });
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  addToCart(item: string, qty: number, e) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.createCart(customer, item, qty);

      this.button = e;
      this.button.textContent = "Adding...";
      this.button.disabled = true;
      this.added = true;
      setTimeout(
        function () {
          this.added = false;
          this.button.textContent = "Add to Cart";
          this.button.disabled = false;
          this.navbar.refreshCart(customer);
        }.bind(this),
        1000
      );
    }
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }
}
