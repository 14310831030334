import { Component, OnInit } from "@angular/core";
import { BsNavbarComponent } from "../bs-navbar/bs-navbar.component";
import { ItemHistoryList } from "../models/item-history";
import { ProductsService } from "../services/products.service";
import { Router } from "@angular/router";
import { GlobalApp } from "../global";

@Component({
  selector: "app-item-history",
  providers: [BsNavbarComponent],
  templateUrl: "./item-history.component.html",
  styleUrls: ["./item-history.component.css"],
})
export class ItemHistoryComponent implements OnInit {
  itemHistoryList: ItemHistoryList[];
  public button;
  public added = false;
  public itemFilter;
  public selectedAge;
  public _opened: boolean = true;

  constructor(
    private navbar: BsNavbarComponent,
    private service: ProductsService,
    private _route: Router,
    public app: GlobalApp
  ) {}

  ngOnInit() {
    window.location.href = "https://ksiautoparts.com";
    this.selectedAge = 90;
    this.getHistory();
  }

  getHistory() {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));

      this.service
        .getItemHistory(customer, this.selectedAge)
        .subscribe((history) => {
          this.itemHistoryList = history;
        });
    }
  }

  onAgeChange() {
    let customer: number = Number(localStorage.getItem("customer"));
    this.service
      .getItemHistory(customer, this.selectedAge)
      .subscribe((history) => {
        this.itemHistoryList = history;
      });
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  addToCart(item: string, qty: number, e) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.createCart(customer, item, qty);

      this.button = e;
      this.button.textContent = "Adding...";
      this.button.disabled = true;
      this.added = true;
      setTimeout(
        function () {
          this.added = false;
          this.button.textContent = "Add to Cart";
          this.button.disabled = false;
          this.navbar.refreshCart(customer);
        }.bind(this),
        1000
      );
    }
  }

  isFavorite(item: string) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.updateFavorite(customer, item);
      setTimeout(
        function () {
          this.service.getItemHistory(customer).subscribe((history) => {
            this.itemHistoryList = history;
          });
        }.bind(this),
        1000
      );
      this._route.navigate(["/itemHistory"]);
    }
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }
}
