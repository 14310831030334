import { LoginList } from "./../models/login-list";
import { login } from "./../models/login";
import { CustomerDetails } from "./../models/customer-details";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomerService } from "./../services/customer.service";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CustomerNumberValidators } from "./customerNumber.validators";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public customers: CustomerDetails[];
  private formData: login;
  private responseData: LoginList;
  public forceChange;

  form = new FormGroup(
    {
      CUSTOMERNUMBER: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
        CustomerNumberValidators.cannotContainSpace,
      ]),
      PASSWORD: new FormControl("", [Validators.required]),
      NEWPASSWORD: new FormControl("", [Validators.required]),
      CONFIRMPASSWORD: new FormControl("", [Validators.required]),
    },
    {
      validators: CustomerNumberValidators.matchPassword,
    }
  );

  updatePassword() {
    this.service.updatePassword(
      this.form.value.CUSTOMERNUMBER,
      this.form.value.PASSWORD,
      this.form.value.NEWPASSWORD
    );
    this._route.navigate(["/"]);
  }

  login() {
    // Check if user is valid
    this.formData = this.form.value;
    this.service.isValidLogin(this.form.value).subscribe((loginData) => {
      this.responseData = loginData;
      if (loginData.ISVALID == "0") {
        this.form.setErrors({
          invalidLogin: true,
        });
      } else {
        if (loginData.ISVALID == "1" && loginData.ISALLOWED == "N") {
          this.form.setErrors({
            notAllowedToLogin: true,
          });
        } else {
          this.forceChange = loginData.ISFORCECHANGE;

          if (loginData.ISFORCECHANGE == "N") {
            // Load customer information
            this.service
              .getCustomer(this.form.value.CUSTOMERNUMBER)
              .subscribe((customer) => {
                this.customers = customer;

                // Load session
                localStorage.setItem(
                  "customer",
                  this.form.value.CUSTOMERNUMBER
                );
                localStorage.setItem("billToName", customer[0].BILLTONAME);
                localStorage.setItem("billToStreet", customer[0].BILLTOSTREET);
                localStorage.setItem("billToCity", customer[0].BILLTOCITY);
                localStorage.setItem("billToState", customer[0].BILLTOSTATE);
                localStorage.setItem(
                  "billToZip",
                  customer[0].BILLTOZIP.toString()
                );
                localStorage.setItem("shipToName", customer[0].SHIPTONAME);
                localStorage.setItem("shipToStreet", customer[0].SHIPTOSTREET);
                localStorage.setItem("shipToCity", customer[0].SHIPTOCITY);
                localStorage.setItem("shipToState", customer[0].SHIPTOSTATE);
                localStorage.setItem(
                  "shipToZip",
                  customer[0].SHIPTOZIP.toString()
                );
                localStorage.setItem("terms", customer[0].TERMSCODE);
                this._route.navigate(["/"]);
              });
          } else {
            // Load customer information
            this.service
              .getCustomer(this.form.value.CUSTOMERNUMBER)
              .subscribe((customer) => {
                this.customers = customer;

                // Load session
                localStorage.setItem(
                  "customer",
                  this.form.value.CUSTOMERNUMBER
                );
                localStorage.setItem("billToName", customer[0].BILLTONAME);
                localStorage.setItem("billToStreet", customer[0].BILLTOSTREET);
                localStorage.setItem("billToCity", customer[0].BILLTOCITY);
                localStorage.setItem("billToState", customer[0].BILLTOSTATE);
                localStorage.setItem(
                  "billToZip",
                  customer[0].BILLTOZIP.toString()
                );
                localStorage.setItem("shipToName", customer[0].SHIPTONAME);
                localStorage.setItem("shipToStreet", customer[0].SHIPTOSTREET);
                localStorage.setItem("shipToCity", customer[0].SHIPTOCITY);
                localStorage.setItem("shipToState", customer[0].SHIPTOSTATE);
                localStorage.setItem(
                  "shipToZip",
                  customer[0].SHIPTOZIP.toString()
                );
                localStorage.setItem("terms", customer[0].TERMSCODE);
              });
          }
        }
      }
    });
  }

  get customerNumber() {
    return this.form.get("CUSTOMERNUMBER");
  }

  get password() {
    return this.form.get("PASSWORD");
  }

  get newPassword() {
    return this.form.get("NEWPASSWORD");
  }

  get confirmPassword() {
    return this.form.get("CONFIRMPASSWORD");
  }

  constructor(
    private service: CustomerService,
    private route: ActivatedRoute,
    private _route: Router
  ) {}

  ngOnInit() {
    window.location.href = "https://ksiautoparts.com";
  }
}
