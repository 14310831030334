import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class FavoritesService {
  private URL = "https://data.autosupplyhouse.com:443/web/services/products";
  private carouselURL = "https://data.autosupplyhouse.com:443/web/services/CarouselPics";

  constructor(private http: HttpClient) {}

  getFavorites(customer: number) {
    return this.http
      .get(this.URL + "/favorite/" + customer)
      .map((response) => response["ITEMS"]);
  }
  
  getPictures() {
    return this.http
      .get(this.carouselURL)
      .map((response) => response["PICS"]);
    }
}
