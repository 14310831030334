import { SubCategoryList } from "./../models/sub-category-list";
import { CategoryService } from "./../services/category.service";
import { Component, OnInit } from "@angular/core";
import "rxjs/add/operator/map";
import { Observable } from "rxjs/Observable";
import { ActivatedRoute, RouterModule, Router } from "@angular/router";

@Component({
  selector: "categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.css"],
})
export class CategoriesComponent implements OnInit {
  public subCats: SubCategoryList[];
  public category;
  public _opened: boolean = true;

  constructor(
    private service: CategoryService,
    private route: ActivatedRoute,
    private _route: Router
  ) {}

  ngOnInit() {
    window.location.href = "https://ksiautoparts.com";
    this.route.queryParams.subscribe((params) => {
      this.category = params["category"];
      this.populateData(this.category);
    });
  }

  private populateData(category: string) {
    this.service.getSubCategories(this.category).subscribe((subCats) => {
      this.subCats = subCats;
    });
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }
}
