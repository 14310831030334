
import {Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'itemSearch'
})
export class ItemSearchPipe implements PipeTransform {
    transform(items: Array<any>, itemSearch: string){
        console.log("items: " + items);
        console.log("filter: " + itemSearch);
        if (items && items.length){
            return items.filter(item =>{
                if (itemSearch && item.itemnumber.toUpperCase().indexOf(itemSearch.toUpperCase()) === -1){
                    return false;
                }

                return true;
           })
        }
        else{
            return items;
        }
    }
}