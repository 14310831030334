import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ProductDetailsService {
  private detailsURL =
    "https://data.autosupplyhouse.com:443/web/services/products";

  constructor(private http: HttpClient) {}

  getProductDetails(product: string) {
    let URL = this.detailsURL + "/details/" + product;
    return this.http.get(URL).map((response) => response["ITEMS"]);
  }

  getRelatedItems(product: string) {
    let URL = this.detailsURL + "/related/" + product;
    return this.http.get(URL).map((response) => response["ITEMS"]);
  }
}
