import { Component, OnInit } from "@angular/core";
import { FeaturedList } from "../models/featured-list";
import { CarouselPicsList } from "../models/carouselPics-list";
import { ProductsService } from "../services/products.service";
import { FavoritesService } from "../services/favorites.service";
import { Router } from "@angular/router";

@Component({
  selector: "home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public featured: FeaturedList[];
  public pictures: CarouselPicsList[];
  public _opened: boolean = true;

  constructor(
    private service: ProductsService,
    private favoritesService: FavoritesService,
    private _route: Router
  ) {}

  ngOnInit() {
    window.location.href = "https://ksiautoparts.com";
    this.service.getFeaturedSubCats().subscribe((subCats) => {
      this.featured = subCats;
    });

    this.favoritesService.getPictures().subscribe((pictures) => {
      this.pictures = pictures;
    });
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }

  onChange(deviceValue) {
    console.log(deviceValue);
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }
}
