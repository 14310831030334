import { SharedService } from "./../services/shared.service";
import { ProductsService } from "./../services/products.service";
import { Router } from "@angular/router";
import { CartCount } from "./../models/cart-count";
import {
  Component,
  OnInit,
  DoCheck,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { Observable } from "rxjs/Observable";
import { GlobalApp } from "../global";
import { CategoryService } from "../services/category.service";
import { MasterCategoryList } from "../models/master-cat-list";

@Component({
  selector: "bs-navbar",
  templateUrl: "./bs-navbar.component.html",
  styleUrls: ["./bs-navbar.component.css"],
})
export class BsNavbarComponent implements OnInit {
  cartCount$: Observable<CartCount>;
  cartCount: number;
  public masterCategories: MasterCategoryList[];

  constructor(
    private service: ProductsService,
    private categoryService: CategoryService,
    public app: GlobalApp,
    private _route: Router,
    private sharedService: SharedService
  ) {
    // Subscribe here, this will automatically update
    // cartcount whenever a change to the subject is made.
    this.sharedService.cartCount.subscribe((value) => {
      this.cartCount = value;
    });
  }

  ngOnInit() {
    // Get single cart count value.
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.cartCount$ = this.service.getCartCount(customer);
      this.cartCount$.subscribe((data: any) => {
        this.cartCount = data.COUNT;
      });
    }
    this.categoryService.getMasterCategories().subscribe((masterCats) => {
      this.masterCategories = masterCats;
      console.log("masters", masterCats);
    });
  }

  filterCategories(e) {
    console.log("html cat", e);
    if (e) {
      this._route.navigate(["/categories"], {
        queryParams: { category: e },
      });
    } else {
      this._route.navigate(["/"]);
    }
  }

  logout() {
    localStorage.clear();
    this.cartCount$ = null;
    this._route.navigate(["/"]);
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }

  // RefreshCart is called from other compoments for cart count to change
  public refreshCart(customer: number) {
    this.cartCount$ = this.service.getCartCount(customer);
    this.cartCount$.subscribe((data: any) => {
      this.sharedService.cartCount.next(data.COUNT);
    });
  }
}
