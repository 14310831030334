import { ItemSearchPipe } from "./../pipes/itemSearch";
import { PartslinkService } from "./services/partslink.service";
import { FavoritesService } from "./services/favorites.service";
import { GlobalApp } from "./global";
import { CartService } from "./services/cart.service";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SidebarModule } from "ng-sidebar";

import { AppComponent } from "./app.component";
import { CategoriesComponent } from "./categories/categories.component";
import { HttpClientModule } from "@angular/common/http";
import { CategoryService } from "./services/category.service";
import { BsNavbarComponent } from "./bs-navbar/bs-navbar.component";
import { CategoryNavComponent } from "./category-nav/category-nav.component";
import { HomeComponent } from "./home/home.component";
import { ProductsComponent } from "./products/products.component";
import { ProductsService } from "./services/products.service";
import { ProdcutDetailsComponent } from "./prodcut-details/prodcut-details.component";
import { ProductDetailsService } from "./services/product-details.service";
import { ShoppingCartComponent } from "./shopping-cart/shopping-cart.component";
import { LoginComponent } from "./login/login.component";
import { CustomerService } from "./services/customer.service";
import { Checkout1Component } from "./checkout1/checkout1.component";
import { OrderHistoryComponent } from "./order-history/order-history.component";
import { OrdersService } from "./services/orders.service";
import { OrderHistoryDetailComponent } from "./order-history-detail/order-history-detail.component";
import { ComfirmationComponent } from "./comfirmation/comfirmation.component";
import { SharedService } from "./services/shared.service";
import { AboutusComponent } from "./aboutus/aboutus.component";
import { PoliciesComponent } from "./policies/policies.component";
import { FavoritesComponent } from "./favorites/favorites.component";
import { ItemHistoryComponent } from "./item-history/item-history.component";
import { ProductSearchComponent } from "./product-search/product-search.component";
import { PartslinkComponent } from "./partslink/partslink.component";
import { TrimPipe } from "../pipes/trim.pipe";
import { IsSecureGuard } from "./services/is-secure-guard.service";
import { ToastrModule } from "ngx-toastr";

@NgModule({
  declarations: [
    AppComponent,
    CategoriesComponent,
    BsNavbarComponent,
    CategoryNavComponent,
    HomeComponent,
    ProductsComponent,
    ProdcutDetailsComponent,
    ShoppingCartComponent,
    LoginComponent,
    Checkout1Component,
    OrderHistoryComponent,
    OrderHistoryDetailComponent,
    ComfirmationComponent,
    AboutusComponent,
    PoliciesComponent,
    FavoritesComponent,
    ItemHistoryComponent,
    ProductSearchComponent,
    PartslinkComponent,
    TrimPipe,
    ItemSearchPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
    }),
    SidebarModule.forRoot(),
    RouterModule.forRoot(
      [
        {
          path: "",
          component: HomeComponent,
          pathMatch: "full",
          canActivate: [IsSecureGuard],
        },
        {
          path: "categories",
          component: CategoriesComponent,
          pathMatch: "full",
        },
        { path: "products", component: ProductsComponent, pathMatch: "full" },
        {
          path: "productDetails",
          component: ProdcutDetailsComponent,
          pathMatch: "full",
        },
        { path: "cart", component: ShoppingCartComponent, pathMatch: "full" },
        { path: "login", component: LoginComponent, pathMatch: "full" },
        { path: "checkout1", component: Checkout1Component, pathMatch: "full" },
        {
          path: "orderHistory",
          component: OrderHistoryComponent,
          pathMatch: "full",
        },
        {
          path: "orderHistoryDetail",
          component: OrderHistoryDetailComponent,
          pathMatch: "full",
        },
        {
          path: "confirmation",
          component: ComfirmationComponent,
          pathMatch: "full",
        },
        { path: "aboutus", component: AboutusComponent, pathMatch: "full" },
        { path: "policies", component: PoliciesComponent, pathMatch: "full" },
        { path: "favorites", component: FavoritesComponent, pathMatch: "full" },
        {
          path: "itemHistory",
          component: ItemHistoryComponent,
          pathMatch: "full",
        },
        {
          path: "search",
          component: ProductSearchComponent,
          pathMatch: "full",
        },
        { path: "partslink", component: PartslinkComponent, pathMatch: "full" },
        { path: "404", component: HomeComponent, pathMatch: "full" },
        { path: "**", component: HomeComponent, pathMatch: "full" },
      ],
      { useHash: true }
    ),
  ],
  providers: [
    CategoryService,
    ProductsService,
    ProductDetailsService,
    CartService,
    CustomerService,
    GlobalApp,
    OrdersService,
    SharedService,
    FavoritesService,
    PartslinkService,
    IsSecureGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
