import { Router } from "@angular/router";
import { CartItems } from "./../models/cart-items";
import { Component, OnInit } from "@angular/core";
import { CartService } from "../services/cart.service";
import { BsNavbarComponent } from "./../bs-navbar/bs-navbar.component";
import { Location } from "@angular/common";

@Component({
  selector: "shopping-cart",
  providers: [BsNavbarComponent],
  templateUrl: "./shopping-cart.component.html",
  styleUrls: ["./shopping-cart.component.css"],
})
export class ShoppingCartComponent implements OnInit {
  public cartItems: CartItems[];
  public _opened: boolean = true;

  constructor(
    private service: CartService,
    private _route: Router,
    private navbar: BsNavbarComponent,
    private _location: Location
  ) {}

  ngOnInit() {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.getCartItems(customer).subscribe((cart) => {
        this.cartItems = cart;
      });
    }
  }

  emptyCart() {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.emptyCart(customer);
      this.cartItems = [];
      setTimeout(
        function () {
          this.navbar.refreshCart(customer);
        }.bind(this),
        1000
      );
    }
  }

  addToCart(item: string) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.increaseByOne(customer, item);
      setTimeout(
        function () {
          this.navbar.refreshCart(customer);
          this.service.getCartItems(customer).subscribe((cart) => {
            this.cartItems = cart;
          });
        }.bind(this),
        1000
      );
    }
  }

  removeFromCart(item: string) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.decreaseByOne(customer, item);
      setTimeout(
        function () {
          this.navbar.refreshCart(customer);
          this.service.getCartItems(customer).subscribe((cart) => {
            this.cartItems = cart;
          });
        }.bind(this),
        1000
      );
    }
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  goBack() {
    this._location.back();
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }
}
