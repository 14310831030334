import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { BsNavbarComponent } from "./../bs-navbar/bs-navbar.component";

@Component({
  selector: "comfirmation",
  providers: [BsNavbarComponent],
  templateUrl: "./comfirmation.component.html",
  styleUrls: ["./comfirmation.component.css"],
})
export class ComfirmationComponent implements OnInit {
  public order: string;

  constructor(
    private route: ActivatedRoute,
    private navbar: BsNavbarComponent
  ) {}

  ngOnInit() {
    window.location.href = "https://ksiautoparts.com";
    this.route.queryParams.subscribe((params) => {
      this.order = params["order"];
      let customer: number = Number(localStorage.getItem("customer"));
      this.navbar.refreshCart(customer);
    });
  }
}
