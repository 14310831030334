import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Cart } from "../models/cart";
import { Favorite } from "../models/favorite";

@Injectable()
export class ProductsService {
  private productURL =
    "https://data.autosupplyhouse.com:443/web/services/products/category";
  private filterURL =
    "https://data.autosupplyhouse.com:443/web/services/categories";
  private filteredProductURL =
    "https://data.autosupplyhouse.com:443/web/services/products/filter";
  private cartURL = "https://data.autosupplyhouse.com:443/web/services/carts";
  private favoriteURL =
    "https://data.autosupplyhouse.com:443/web/services/products";
  private itemHistoryURL =
    "https://data.autosupplyhouse.com:443/web/services/history/history";
  private featuredURL =
    "https://data.autosupplyhouse.com:443/web/services/history/featured";
  private searchURL =
    "https://data.autosupplyhouse.com:443/web/services/history/search";
  private cart: Cart;
  private customer: String;
  private favorite: Favorite;

  constructor(private http: HttpClient) {}

  getProducts(subCategory: string, masterCategory: string) {
    if (localStorage.getItem("customer")) {
      this.customer = localStorage.getItem("customer");
    } else {
      this.customer = "00000";
    }
    return this.http
      .get(
        this.productURL +
          "/" +
          masterCategory +
          "/subcategory/" +
          subCategory +
          "/customer/" +
          this.customer
      )
      .map((response) => response["ITEMS"]);
  }

  getFilters(subCategory: string, mainCategory: string) {
    let URL =
      this.filterURL + "/subcat/" + subCategory + "/mastercat/" + mainCategory;
    return this.http.get(URL).map((response) => response["FILTERS"]);
  }

  getSearchProducts(searchValue: string) {
    let URL = this.searchURL + "/" + searchValue;
    return this.http.get(URL).map((response) => response["ITEMS"]);
  }

  getFilteredProducts(subCategory: string, inClause: string) {
    if (localStorage.getItem("customer")) {
      this.customer = localStorage.getItem("customer");
    } else {
      this.customer = "00000";
    }
    let URL =
      this.filteredProductURL +
      "/" +
      this.customer +
      inClause +
      "_" +
      subCategory;
    return this.http.get(URL).map((response) => response["ITEMS"]);
  }

  createCart(customer: number, item: string, qty: number) {
    let cartData = { CUSTOMER: customer, ITEMNUMBER: item, QUANTITY: qty };
    let URL = this.cartURL;
    let headers = new HttpHeaders({
      "content-type": "application/json",
      Accept: "application/json",
    });
    this.http
      .post(URL, JSON.stringify(cartData), { headers: headers })
      .subscribe((response) => {});
  }

  updateFavorite(customer: number, item: string) {
    let favoriteData = { CUSTOMER: customer, ITEMNUMBER: item };
    let URL = this.favoriteURL;
    let headers = new HttpHeaders({
      "content-type": "application/json",
      Accept: "application/json",
    });
    this.http
      .post(URL, JSON.stringify(favoriteData), { headers: headers })
      .subscribe((response) => {});
  }

  getCartCount(customer: number) {
    let URL = this.cartURL + "/cartcount/" + customer;
    return this.http.get(URL).map((response) => response["CART"][0]);
  }

  getItemHistory(customer: number, age: number) {
    let URL = this.itemHistoryURL + "/" + customer + "/" + age;
    return this.http.get(URL).map((response) => response["ITEMS"]);
  }

  getFeaturedSubCats() {
    let URL = this.featuredURL + "/";
    return this.http.get(URL).map((response) => response["ITEMS"]);
  }
}
