import { Component, OnInit } from "@angular/core";
import { CategoryService } from "../services/category.service";
import { ActivatedRoute } from "@angular/router";
import { MasterCategoryList } from "../models/master-cat-list";

@Component({
  selector: "category-nav",
  templateUrl: "./category-nav.component.html",
  styleUrls: ["./category-nav.component.css"],
})
export class CategoryNavComponent implements OnInit {
  public masterCategories: MasterCategoryList[];
  public category;

  constructor(
    private service: CategoryService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.category = params["category"];
      this.populateData(this.category);
    });
  }

  private populateData(category: string) {
    //if (!category) {
    this.service.getMasterCategories().subscribe((masterCats) => {
      this.masterCategories = masterCats;
    });

    //}
    //else {
    //  this.service.get(this.category)
    //    .subscribe(masterCats => {
    //      this.masterCategories = masterCats;
    //    });
    //}
  }
}
