import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { OrdersService } from "../services/orders.service";
import { OrderHeaderList } from "../models/order-header-list";
import { OrderDetailList } from "../models/order-detail-list";
import { Router } from "@angular/router";
import { ProductsService } from "../services/products.service";
import { GlobalApp } from "../global";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "order-history",
  templateUrl: "./order-history.component.html",
  styleUrls: ["./order-history.component.css"],
})
export class OrderHistoryComponent implements OnInit {
  orderHeaders: OrderHeaderList[];
  orderDetails: OrderDetailList[];
  @ViewChild("emailAddress") emailAddress: ElementRef;

  public button;
  public added = false;
  public _opened: boolean = true;
  public orderNo: string;
  public invoiceNumber: string;

  constructor(
    private service: OrdersService,
    private _route: Router,
    private productService: ProductsService,
    private toastr: ToastrService,
    public app: GlobalApp
  ) {}

  ngOnInit() {
    this.getOrderHeaders();
  }

  getOrderHeaders() {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));

      this.service.getOrderHeaders(customer).subscribe((orders) => {
        this.orderHeaders = orders;
      });
    }
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  getDetails(order: string) {
    this.service.getOrderDetails(order).subscribe((orders) => {
      this.orderDetails = orders;
      this.orderNo = order.toString();
    });
  }

  printInvoice() {
    if (localStorage.getItem("customer")) {
      let customer: string = String(localStorage.getItem("customer"));
      if (!this.emailAddress.nativeElement.value) {
        this.toastr.error("Error", "Please enter email address", {
          progressBar: true,
        });
      } else {
        this.invoiceNumber = " ";
        this.service.printInvoice(
          this.invoiceNumber,
          customer,
          this.orderNo,
          this.emailAddress.nativeElement.value
        );
        this.emailAddress.nativeElement.value = "";
        this.toastr.success("Success", "Invoice email generated successfully", {
          progressBar: true,
        });
      }
    }
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }

  callDetails(item: string) {
    this._route.navigate(["/productDetails"], { queryParams: { item: item } });
  }

  isFavorite(item: string) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.productService.updateFavorite(customer, item);
    }
  }
}
