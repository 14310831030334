import { TrimPipe } from "./../../pipes/trim.pipe";
import { GlobalApp } from "./../global";
import { BsNavbarComponent } from "./../bs-navbar/bs-navbar.component";
import { Observable } from "rxjs/Observable";
import { CartItems } from "./../models/cart-items";
import { ProductList } from "./../models/product-list";
import { FilterList } from "./../models/filter-list";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  DoCheck,
} from "@angular/core";
import { ProductsService } from "../services/products.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CartCount } from "../models/cart-count";
import { CategoryService } from "../services/category.service";
import { MasterCategoryList } from "../models/master-cat-list";

@Component({
  selector: "app-products",
  providers: [BsNavbarComponent],
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.css"],
})
export class ProductsComponent implements OnInit {
  public products: ProductList[];
  public filters: FilterList[];
  public category;
  public selectedFilters = [];
  public cartItems: CartItems[];
  public added = false;
  public button;
  private cartCount$: Observable<CartCount>;
  public mainCat;
  public masterCategories: MasterCategoryList[];
  public _opened: boolean = true;

  constructor(
    private service: ProductsService,
    private route: ActivatedRoute,
    private _route: Router,
    private navbar: BsNavbarComponent,
    private categoryService: CategoryService,
    public app: GlobalApp
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.category = params["subcategory"];
      this.mainCat = params["category"];
      this.populateData(this.category, this.mainCat);
    });
    this.selectedFilters = [];
  }

  private populateData(category: string, mainCat: string) {
    this.service.getProducts(category, mainCat).subscribe((products) => {
      this.products = products;
    });

    this.categoryService.getMasterCategories().subscribe((masterCats) => {
      this.masterCategories = masterCats;
    });

    this.service.getFilters(category, mainCat).subscribe((filters) => {
      this.filters = filters;
    });
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  clearFilters() {
    this.route.queryParams.subscribe((params) => {
      this.category = params["subcategory"];
      this.mainCat = params["category"];
      this.service
        .getProducts(this.category, this.mainCat)
        .subscribe((products) => {
          this.products = products;
        });
      this.filters.forEach((item) => {
        item.Checked = false;
      });
      this.selectedFilters = [];
      this._route.navigate(["/products"], {
        queryParams: { subcategory: this.category },
      });
    });
  }

  filterProducts(e) {
    //if (e.target.checked) {
    // this.selectedFilters.push(e.target.value);
    if (e) {
      this.selectedFilters.push(e);
      console.log("filters", this.selectedFilters);
    } else {
      this.selectedFilters = [];
    }
    // } else {
    //   let index = this.selectedFilters.indexOf(e.target.value);
    //   this.selectedFilters.splice(index, 1);
    // }

    // Build "in clause" to pass to service to subset list
    let inClause = "";
    for (let i of this.selectedFilters) {
      if (inClause) {
        inClause =
          inClause +
          " OR LOCATE('" +
          i.toUpperCase() +
          "', UPPER(filter)) <> 0";
      } else {
        inClause = "LOCATE('" + i.toUpperCase() + "', UPPER(filter)) <> 0";
      }
      console.log("clause", inClause);
    }

    // Call service to returned filter list
    if (inClause.indexOf("LOCATE") != -1) {
      this.service
        .getFilteredProducts(this.category, inClause)
        .subscribe((products) => {
          this.products = products;
        });
    } else {
      this.service
        .getProducts(this.category, this.mainCat)
        .subscribe((products) => {
          this.products = products;
        });
    }
  }

  addToCart(item: string, qty: number, e) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.createCart(customer, item, qty);

      this.button = e;
      this.button.textContent = "Adding...";
      this.button.disabled = true;
      this.added = true;
      setTimeout(
        function () {
          this.added = false;
          this.button.textContent = "Add to Cart";
          this.button.disabled = false;
          this.navbar.refreshCart(customer);
        }.bind(this),
        1000
      );
    }
  }

  isFavorite(item: string) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.updateFavorite(customer, item);
      setTimeout(
        function () {
          this.route.queryParams.subscribe((params) => {
            let category = params["category"];
            let subcategory = params["subcategory"];
            this.service
              .getProducts(subcategory, category)
              .subscribe((products) => {
                this.products = products;
              });
            this._route.navigate(["/products"], {
              queryParams: { subcategory: subcategory, category: category },
            });
          });
        }.bind(this),
        1000
      );
    }
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }
}
