import { ActivatedRoute } from "@angular/router";
import { OrdersService } from "./../services/orders.service";
import { Component, OnInit } from "@angular/core";
import { OrderDetailList } from "../models/order-detail-list";

@Component({
  selector: "order-history-detail",
  templateUrl: "./order-history-detail.component.html",
  styleUrls: ["./order-history-detail.component.css"],
})
export class OrderHistoryDetailComponent implements OnInit {
  orderDetails: OrderDetailList[];
  order: string;

  constructor(private service: OrdersService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.order = params["order"];
      this.getOrderDetails(this.order);
    });
  }

  getOrderDetails(order: string) {
    this.service.getOrderDetails(order).subscribe((orders) => {
      this.orderDetails = orders;
    });
  }
}
