
import { Observable } from 'rxjs/Observable';
import { AbstractControl } from "@angular/forms/src/model";
import { ValidationErrors } from "@angular/forms/src/directives/validators";


export class CheckoutValidators {
    static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
        if ((control.value as string).indexOf(' ') >= 0)
            return {cannotContainSpace: true }

        return null;
    }

    static cannotContainDash(control: AbstractControl) : ValidationErrors | null {
        if ((control.value as string).indexOf('-') >= 0)
            return {cannotContainDash: true }

        return null;
    }

    static cannotContainNonNumbers(control: AbstractControl) : ValidationErrors | null {
        if ((control.value as string).match(/^[0-9]+$/) == null)
            return {cannotContainNonNumbers: true }

        return null;
    }

    //static shouldBeUnique(control: AbstractControl) : Observable<ValidationErrors | null> {

    //}
}