import { FavoritesService } from "./../services/favorites.service";
import { MyFavoriteList } from "./../models/my-favorites";
import { Component, OnInit } from "@angular/core";
import { ProductsService } from "../services/products.service";
import { BsNavbarComponent } from "../bs-navbar/bs-navbar.component";
import { Router } from "@angular/router";

@Component({
  selector: "favorites",
  providers: [BsNavbarComponent],
  templateUrl: "./favorites.component.html",
  styleUrls: ["./favorites.component.css"],
})
export class FavoritesComponent implements OnInit {
  favorites: MyFavoriteList[];
  public button;
  public added = false;
  public _opened: boolean = true;

  constructor(
    private service: FavoritesService,
    private navbar: BsNavbarComponent,
    private productService: ProductsService,
    private _route: Router
  ) {}

  ngOnInit() {
    this.getFavorites();
  }

  getFavorites() {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));

      this.service.getFavorites(customer).subscribe((favs) => {
        this.favorites = favs;
      });
    }
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  addToCart(item: string, qty: number, e) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.productService.createCart(customer, item, qty);

      this.button = e;
      this.button.textContent = "Adding...";
      this.button.disabled = true;
      this.added = true;
      setTimeout(
        function () {
          this.added = false;
          this.button.textContent = "Add to Cart";
          this.button.disabled = false;
          this.navbar.refreshCart(customer);
        }.bind(this),
        1000
      );
    }
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }
}
