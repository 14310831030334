import { PartslinkService } from "./../services/partslink.service";
import { Component, OnInit } from "@angular/core";
import { GlobalApp } from "../global";
import { ProductsService } from "../services/products.service";
import { BsNavbarComponent } from "../bs-navbar/bs-navbar.component";
import { Router } from "@angular/router";

@Component({
  selector: "partslink",
  providers: [BsNavbarComponent],
  templateUrl: "./partslink.component.html",
  styleUrls: ["./partslink.component.css"],
})
export class PartslinkComponent implements OnInit {
  public yearList: any[];
  public makeList: any[];
  public modelList: any[];
  public categoryList: any[];
  public masterCategoryList: any[];
  public partList: any[];
  private year: string;
  private make: string;
  private model: string;
  private masterCategoryCode: string;
  public button;
  public added = false;
  public modalImageItem: string;
  public modalItem: string;
  public modalDesc: string;
  public modalVars: string;
  public modalOEM: string;
  public modalYears: string;
  public _opened: boolean = true;

  constructor(
    public app: GlobalApp,
    private service: PartslinkService,
    private productService: ProductsService,
    private navbar: BsNavbarComponent,
    private _route: Router
  ) {}

  ngOnInit() {
    this.service.getYears().subscribe((years) => {
      this.yearList = years;
      this.partList = null;
      this.makeList = null;
    });
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  getMakes(year: string) {
    this.year = year;
    this.service.getMakes(year).subscribe((makes) => {
      this.makeList = makes;
      this.modelList = null;
      this.partList = null;
    });
  }

  getModels(year: string, make: string) {
    this.make = make;
    this.service.getModels(year, make).subscribe((models) => {
      this.modelList = models;
      this.categoryList = null;
      this.partList = null;
    });
  }

  getCategories(masterCategoryCode: string) {
    this.masterCategoryCode = masterCategoryCode;
    this.service
      .getCategories(this.year, this.make, this.model, this.masterCategoryCode)
      .subscribe((cats) => {
        this.categoryList = cats;
        this.partList = null;
      });
  }

  getMasterCategories(model: string) {
    this.model = model;
    this.service.getMasterCategories().subscribe((cats) => {
      this.masterCategoryList = cats;
      this.partList = null;
    });
  }

  getParts(category: string) {
    if (category == "showall") {
      this.service
        .getPartsAll(this.year, this.make, this.model)
        .subscribe((parts) => {
          this.partList = parts;
        });
    } else {
      this.service
        .getPartsByCategory(this.year, this.make, this.model, category)
        .subscribe((parts) => {
          this.partList = parts;
        });
    }
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }

  makeItemVisible(
    item: string,
    desc: string,
    variables: string,
    oem: string,
    yearRange: string
  ) {
    this.modalImageItem = item.substring(0, 9) + ".jpg";
    this.modalItem = item;
    this.modalDesc = desc;
    this.modalVars = variables;
    this.modalOEM = oem;
    this.modalYears = yearRange;
  }

  addToCart(item: string, qty: number, e) {
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.productService.createCart(customer, item, qty);

      this.button = e;
      this.button.textContent = "Adding...";
      this.button.disabled = true;
      this.added = true;
      setTimeout(
        function () {
          this.added = false;
          this.button.textContent = "Add";
          this.button.disabled = false;
          this.navbar.refreshCart(customer);
        }.bind(this),
        1000
      );
    }
  }
}
