import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class CartService {
  private cartURL = "https://data.autosupplyhouse.com:443/web/services/carts";
  private orderURL =
    "https://data.autosupplyhouse.com:443/web/services/checkout";

  constructor(private http: HttpClient) {}

  getCartCount(customer: number) {
    let URL = this.cartURL + "/cartcount/" + customer;
    return this.http.get(URL).map((response) => response["CART"]);
  }

  getCartItems(customer: number) {
    let URL = this.cartURL + "/cartitems/" + customer;
    return this.http.get(URL).map((response) => response["CART"]);
  }

  getNextOrder() {
    let URL = this.orderURL + "/";
    return this.http.get(URL).map((response) => response["THISORDER"]);
  }

  createOrder(cartJSON: string, customerOrder: string) {
    let URL = this.orderURL + "/" + customerOrder;
    let headers = new HttpHeaders({
      "Content-type": "application/json",
      Accept: "application/json",
    });
    console.log(cartJSON);
    this.http
      .post(URL, cartJSON, { headers: headers })
      .subscribe((response) => {});
  }

  emptyCart(customer: number) {
    let URL = this.cartURL + "/" + customer;
    this.http.delete(URL).subscribe((response) => {});
  }

  increaseByOne(customer: number, item: string) {
    let URL = this.cartURL + "/increase/" + customer + "-" + item;
    let headers = new HttpHeaders({
      "Content-type": "application/json",
      Accept: "application/json",
    });
    this.http.put(URL, { headers: headers }).subscribe((response) => {});
  }

  decreaseByOne(customer: number, item: string) {
    let URL = this.cartURL + "/decrease/" + customer + "-" + item;
    let headers = new HttpHeaders({
      "Content-type": "application/json",
      Accept: "application/json",
    });
    this.http.put(URL, { headers: headers }).subscribe((response) => {});
  }
}
