import { Observable } from 'rxjs/Observable';
import { AbstractControl } from "@angular/forms/src/model";
import { ValidationErrors } from "@angular/forms/src/directives/validators";


export class CustomerNumberValidators {
    static cannotContainSpace(control: AbstractControl) : ValidationErrors | null {
        if ((control.value as string).indexOf(' ') >= 0)
            return {cannotContainSpace: true }

        return null;
    }

    static matchPassword(control: AbstractControl) : ValidationErrors | null {
        let newPassword = control.get('NEWPASSWORD').value;
        let confirmPassword = control.get('CONFIRMPASSWORD').value;

        if (newPassword != confirmPassword && newPassword != ' ')
            return {matchPassword: true }

        return null;
    }

}