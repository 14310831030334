import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable()
export class OrdersService {
  private URL = "https://data.autosupplyhouse.com:443/web/services/orders";

  private emailURL =
    "https://data.autosupplyhouse.com/web/services/InvoiceEmail";

  constructor(private http: HttpClient) {}

  getOrderHeaders(customer: number) {
    return this.http
      .get(this.URL + "/header/" + customer)
      .map((response) => response["ORDERS"]);
  }

  getOrderDetails(order: string) {
    return this.http
      .get(this.URL + "/detail/" + order)
      .map((response) => response["ORDERS"]);
  }

  printInvoice(
    invoiceNumber: string,
    customer: string,
    orderNo: string,
    emailAddress: string
  ) {
    const params = new HttpParams()
      .set("order", orderNo)
      .set("customer", customer)
      .set("invoice", invoiceNumber)
      .set("email", emailAddress);

    console.log("Parameters", params);

    return this.http
      .get(this.emailURL, { params })
      .subscribe((response) => console.log("RESPONSE", response));
  }
}
