
import { ProductDetailList } from './../models/product-details-list';
import { Component, OnInit } from '@angular/core';
import { ProductDetailsService } from '../services/product-details.service';
import { ActivatedRoute } from '@angular/router';
import { RelatedItemList } from '../models/related-items-list';
import { BsNavbarComponent } from './../bs-navbar/bs-navbar.component';
import { ProductsService } from '../services/products.service';
import {Location} from '@angular/common';
import { GlobalApp } from '../global';

@Component({
  selector: 'prodcut-details',
  providers: [BsNavbarComponent],
  templateUrl: './prodcut-details.component.html',
  styleUrls: ['./prodcut-details.component.css']
})
export class ProdcutDetailsComponent implements OnInit {
  public productDetails: ProductDetailList[];
  public relatedItems: RelatedItemList[];
  private item;
  public button;
  public added = false;

  constructor(private service: ProductDetailsService, 
    private route: ActivatedRoute,
    private navbar: BsNavbarComponent,
    private productService: ProductsService,
    private _location: Location,
    public app: GlobalApp) { }

  ngOnInit() {
    this.route
    .queryParams
    .subscribe(params => {
      this.item = params['item'];
      this.populateData(this.item);
    });
  }

  private populateData(item: string) {
    
          this.service.getProductDetails(this.item)
          .subscribe(items => {
            this.productDetails = items;
          });

          this.service.getRelatedItems(this.item)
          .subscribe(items => {
            this.relatedItems = items;
          });

  }

  addToCart(item: string, qty: number, e) {
    if (localStorage.getItem('customer')) {
      let customer: number = Number(localStorage.getItem('customer'));
      this.productService.createCart(customer,item,qty);

      this.button = e;
      this.button.textContent = "Adding...";
      this.button.disabled = true;
      this.added = true;
      setTimeout(function() {
        this.added = false;
        this.button.textContent = "Add to Cart";
        this.button.disabled = false;
        this.navbar.refreshCart(customer);
      }.bind(this), 1000);

    }
  }

  goBack() {
    this._location.back();
  }

}
