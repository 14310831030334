import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { login } from "../models/login";

@Injectable()
export class CustomerService {
  private cartURL = "https://data.autosupplyhouse.com:443/web/services/login";
  private loginData: any;

  constructor(private http: HttpClient) {}

  getCustomer(customer: number) {
    let URL = this.cartURL + "/" + customer;
    return this.http.get(URL).map((response) => response["CUSTOMERS"]);
  }

  isValidLogin(data: any) {
    let URL = this.cartURL;
    this.loginData = {
      CUSTOMERNUMBER: +data.CUSTOMERNUMBER,
      PASSWORD: data.PASSWORD,
    };
    let headers = new HttpHeaders({
      "content-type": "application/json",
      Accept: "application/json",
    });
    return this.http
      .post(URL, JSON.stringify(this.loginData), { headers: headers })
      .map((response) => response["VALID"]);
  }

  updatePassword(customer: number, password: string, newPassword: string) {
    let URL = this.cartURL;
    let updateData = {
      CUSTOMERNUMBER: customer,
      PASSWORD: password,
      NEWPASSWORD: newPassword,
    };
    let headers = new HttpHeaders({
      "content-type": "application/json",
      Accept: "application/json",
    });
    return this.http
      .put(URL, JSON.stringify(updateData), { headers: headers })
      .subscribe((response) => {});
  }
}
