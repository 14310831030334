import { Observable } from "rxjs/Observable";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class PartslinkService {
  private yearURL =
    "https://data.autosupplyhouse.com:443/web/services/partslink/years";
  private makeURL =
    "https://data.autosupplyhouse.com:443/web/services/partslink/year";
  private catURL =
    "https://data.autosupplyhouse.com:443/web/services/partslink2/cat/year";
  private masterCatURL =
    "https://data.autosupplyhouse.com:443/web/services/partslink2";

  constructor(private http: HttpClient) {}

  getYears() {
    let URL = this.yearURL;
    return this.http.get(URL).map((response) => response["YEARS"]);
  }

  getMakes(year: string) {
    let URL = this.makeURL + "/" + year;
    return this.http.get(URL).map((response) => response["MAKES"]);
  }

  getModels(year: string, make: string) {
    let URL = this.makeURL + "/" + year + "/make/" + make;
    return this.http.get(URL).map((response) => response["MODELS"]);
  }

  getMasterCategories() {
    let URL = this.masterCatURL;
    return this.http.get(URL).map((response) => response["MASTERCATS"]);
  }

  getCategories(year: string, make: string, model: string, masterCat: string) {
    let URL =
      this.catURL +
      "/" +
      year +
      "/make/" +
      make +
      "/model/" +
      model.trim() +
      "_" +
      masterCat;
    return this.http.get(URL).map((response) => response["CATEGORIES"]);
  }

  getPartsAll(year: string, make: string, model: string) {
    let URL = this.makeURL + "/" + year + "/make/" + make + "/model/" + model;
    return this.http.get(URL).map((response) => response["PARTS"]);
  }

  getPartsByCategory(
    year: string,
    make: string,
    model: string,
    category: string
  ) {
    let encodedCat = encodeURI(category);
    let URL =
      this.makeURL +
      "/" +
      year +
      "/make/" +
      make +
      "/modelcat/" +
      model +
      "_" +
      encodedCat;
    return this.http.get(URL).map((response) => response["PART"]);
  }
}
