import { NextOrder } from "./../models/next-order";
import { Router } from "@angular/router";
import { CartItems } from "./../models/cart-items";
import { CartService } from "./../services/cart.service";
import { GlobalApp } from "./../global";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CheckoutValidators } from "./checkout.validators";

@Component({
  selector: "checkout1",
  templateUrl: "./checkout1.component.html",
  styleUrls: ["./checkout1.component.css"],
})
export class Checkout1Component implements OnInit {
  public cartItems = [];
  private nextOrder = [];
  private orderNum: number;
  private order: string;
  public button;
  public chosenDelivery;
  public _opened: boolean = true;

  constructor(
    public app: GlobalApp,
    private service: CartService,
    private _route: Router
  ) {}

  form = new FormGroup({
    paymentMethod: new FormControl("", [Validators.required]),
    creditCard: new FormControl("", [
      Validators.required,
      Validators.minLength(15),
      CheckoutValidators.cannotContainSpace,
      CheckoutValidators.cannotContainDash,
      CheckoutValidators.cannotContainNonNumbers,
    ]),
    cardMonth: new FormControl("", [Validators.required]),
    cardYear: new FormControl("", [Validators.required]),
    CVV: new FormControl("", [
      Validators.required,
      Validators.minLength(3),
      CheckoutValidators.cannotContainSpace,
      CheckoutValidators.cannotContainNonNumbers,
    ]),
    zipCode: new FormControl("", [
      Validators.required,
      Validators.minLength(5),
      CheckoutValidators.cannotContainSpace,
      CheckoutValidators.cannotContainNonNumbers,
    ]),
    creditCardName: new FormControl("", [Validators.required]),
    paymentOption: new FormControl("", [Validators.required]),
    PONumber: new FormControl("", [Validators.maxLength(13)]),
    deliveryOption: new FormControl("", [Validators.required]),
  });

  get paymentMethod() {
    return this.form.get("paymentMethod");
  }

  get paymentOption() {
    return this.form.get("paymentOption");
  }

  get deliveryOption() {
    return this.form.get("deliveryOption");
  }

  get creditCard() {
    return this.form.get("creditCard");
  }

  get cardMonth() {
    return this.form.get("cardMonth");
  }

  get cardYear() {
    return this.form.get("cardYear");
  }

  get CVV() {
    return this.form.get("CVV");
  }

  get creditCardName() {
    return this.form.get("creditCardName");
  }

  get zipCode() {
    return this.form.get("zipCode");
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  ngOnInit() {
    window.location.href = "https://ksiautoparts.com";
    if (localStorage.getItem("customer")) {
      let customer: number = Number(localStorage.getItem("customer"));
      this.service.getCartItems(customer).subscribe((cart) => {
        this.cartItems = cart;
      });
    }
  }

  theSearch(searchValue) {
    this._route.navigate(["/search", { searchData: searchValue }]);
  }

  formSubmit() {
    // This method is used for credit card processing
    this.cartItems.forEach((item, index) => {
      this.cartItems[index].CREDITCARD = this.form.value.creditCard;
      this.cartItems[index].CCMONTH = this.form.value.cardMonth;
      this.cartItems[index].CCYEAR = this.form.value.cardYear;
      this.cartItems[index].CVV = this.form.value.CVV;
      this.cartItems[index].PAYMENTMETHOD = this.form.value.paymentMethod;
      this.cartItems[index].CARDNAME = this.form.value.creditCardName;
      this.cartItems[index].PONUMBER = this.form.value.PONumber;
      this.cartItems[index].CCZIP = this.form.value.zipCode;
      this.cartItems[index].DELIVERYMETHOD = this.form.value.deliveryOption;
    });

    // Get next available order number
    this.service.getNextOrder().subscribe((order) => {
      this.nextOrder = order;
      this.orderNum = this.nextOrder[0].NEXTORDER;

      let customer: string = localStorage.getItem("customer");
      this.order = String(this.orderNum);
      let customerOrder = customer + "-" + this.order;
      console.log(JSON.stringify(this.cartItems));
      this.service.createOrder(JSON.stringify(this.cartItems), customerOrder);

      setTimeout(
        function () {
          this._route.navigate(["/confirmation"], {
            queryParams: { order: this.order },
          });
        }.bind(this),
        1000
      );
    });
  }

  formSubmitPO() {
    // This method is used for PO processing

    this.cartItems.forEach((item, index) => {
      this.cartItems[index].PONUMBER = this.form.value.PONumber;
      this.cartItems[index].DELIVERYMETHOD = this.form.value.deliveryOption;
    });

    // Get next available order number
    this.service.getNextOrder().subscribe((order) => {
      this.nextOrder = order;
      this.orderNum = this.nextOrder[0].NEXTORDER;

      let customer: string = localStorage.getItem("customer");
      this.order = String(this.orderNum);
      let customerOrder = customer + "-" + this.order;
      this.service.createOrder(JSON.stringify(this.cartItems), customerOrder);

      setTimeout(
        function () {
          this._route.navigate(["/confirmation"], {
            queryParams: { order: this.order },
          });
        }.bind(this),
        1000
      );
    });
  }

  placeOrder() {
    // Get next available order number
    this.service.getNextOrder().subscribe((order) => {
      this.nextOrder = order;
      this.orderNum = this.nextOrder[0].NEXTORDER;

      let customer: string = localStorage.getItem("customer");
      this.order = String(this.orderNum);
      let customerOrder = customer + "-" + this.order;
      this.service.createOrder(JSON.stringify(this.cartItems), customerOrder);

      setTimeout(
        function () {
          this._route.navigate(["/confirmation"], {
            queryParams: { order: this.order },
          });
        }.bind(this),
        1000
      );
    });
  }
}
